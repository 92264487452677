<template>
  <div>
    <div class="footer">
      <div>
        <img class="logo wow fadeInDown" src="@/assets/image/bscm/logo.png" alt="">
      </div>
      <ul class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">
        <li>
          <a :href="$config.twitterUrl" target="_Twitte">
            <img src="@/assets/image/bscm/Twitte.png" alt="">
          </a>
        </li>
        <li>
          <a :href="$config.tgUrl" target="_Telegram">
            <img src="@/assets/image/bscm/Telegram.png" alt="">
          </a>
        </li>
        
      </ul>
      <p class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s"> COPYRIGHT © 2024 BSCM STUDIO. ALL RIGHTS RESERVED</p>
    </div>
  </div>
</template>

<script>
export default{
  mounted(){
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
  },
}
</script>


<style lang="scss" scoped>
.footer{
  position: relative;
  padding-top: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo{
    width:111px;
    margin-bottom: 30px;
  }
  ul{
    display: flex;
    margin-bottom: 25px;
    li{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #6666;
      border-radius: 50%;
      color: #eee;
      padding: 8px;
      a{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
   
    li+li{
      margin-left: 20px;
    }
    li:hover{
      background: rgba(255,255,255,.3);
      color: #000;
      transition: all 0.2s ease-in;
      span{
        color: #000;
      }
    }
  }
  p{
    font-size: 14px;
    text-transform: uppercase;
    color: #BFBFBF;
    letter-spacing: normal;
    line-height: 24px;
    padding-bottom: 96px;
  }
}
@media screen and (max-width:768px) {
  
  .footer{
    position: relative;
    padding-top: 60px;
    >div{
      .logo{
        width:57px;
        margin-bottom: 30px;
      }
    }
    p{
      padding-bottom: 40px;
    }

  }
  .footer::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 8px;
    top: 0px;
    background: url('../assets/image/h5_line.png') no-repeat center center/100%;
  }
  
}
</style>